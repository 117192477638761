import React, { useEffect, useRef } from "react";
import "./index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import map from "../../../assets/worldMap.png";
import ReviewStarImg from "../../../assets/review-star.svg";

import Slider from "react-slick";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { isMobileMedia, isTabletMedia } from "../../../utils";

import { FEEDBACKS_DATA } from "../../../const/general";
import { useStore } from "../../../store";

const CustomSlide = ({ feedbackData }) => {
  const { username, text, title, link, rate } = feedbackData;

  return (
    <div className="feedbacks_card">
      <p className="name">{username}</p>
      <div className="feedbacks_card-stars">
        {[...new Array(rate)].map(() => (
          <img src={ReviewStarImg} className="feedbacks_card-star" alt="star" />
        ))}
      </div>
      <p className="feedbacks_card-head">
        <a href={link} target="_blank" rel="noreferrer">
          {title}
        </a>
      </p>
      <p className="feedback">{text}</p>
    </div>
  );
};

const NextArrow = (props) => {
  const { style, onClick } = props;
  return (
    <div
      className="arrowNext"
      onClick={onClick}
      style={{
        ...style,
      }}
    />
  );
};
const PrevArrow = ({ className, style, onClick }) => {
  return (
    <div
      className="arrowPrev"
      style={{
        ...style,
      }}
      onClick={onClick}
    />
  );
};

const CustomersSection = ({ handleSelectPlan }) => {
  const isMobile = isMobileMedia();
  const isTablet = isTabletMedia();

  const titleRef = useRef(null);
  const desc1Ref = useRef(null);
  const desc2Ref = useRef(null);
  const btnRef = useRef(null);
  const containerRef = useRef(null);
  const mapRef = useRef(null);

  const { ONE_YEAR_TRIAL_PLAN } = useStore();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: titleRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    tl1
      .to(titleRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.inOut",
      })
      .to(desc1Ref.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.inOut",
      })
      .to(
        desc2Ref.current,
        {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        },
        "<"
      )
      .to(btnRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.inOut",
      });
  }, []);

  useEffect(() => {
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    tl2.to(
      mapRef.current,
      {
        maskPosition: 0,
        "-webkit-mask-position": 0,
        duration: 2.3,
        ease: "linear",
      },
      "<"
    );
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: isMobile ? 1 : isTablet ? 2.1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <section className="world_section">
        <h3 className="world_title">Our servers around the world</h3>
        <p className="world_subtitle">The whole world is open to you</p>
        <div className="world_wrapper" ref={containerRef}>
          <img src={map} alt="map" className="world_map" ref={mapRef} />
        </div>
      </section>
      <section className="feedbacks_section">
        <h3 className="feedbacks_title">
          Our customer network is growing every day
        </h3>
        <div className="feedbacks_slider" id="reviews">
          <Slider {...settings}>
            {FEEDBACKS_DATA.map((feedback, i) => (
              <CustomSlide index={i} feedbackData={feedback} key={i} />
            ))}
          </Slider>
        </div>

        <div className="freeTrial_block">
          <h3 className="freeTrial_title" ref={titleRef}>
            Try our product for free
          </h3>
          <p className="freeTrial_desc" ref={desc1Ref}>
            Still haven't decided on a VPN?
          </p>
          <p className="freeTrial_desc" ref={desc2Ref}>
            Get a 72-hour trial!
          </p>
          <button
            className="secondary-button freeTrial_btn"
            ref={btnRef}
            onClick={() => handleSelectPlan(ONE_YEAR_TRIAL_PLAN, "freeTrial")}
          >
            Get Free Trial
          </button>
        </div>
      </section>
    </>
  );
};

export default CustomersSection;
