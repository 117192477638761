import "./index.scss";

import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

import { getRem, isDesktopMedia } from "../../../utils";

import { useStore } from "../../../store";

const animationTextContent = [
  "Fully Encrypted Internet",
  "Ensure Privacy & Security",
  "High Speed & Open access",
  "No Regional Limitations",
];

const WeOfferSection = ({ handleSelectPlan }) => {
  const isDesktop = isDesktopMedia();

  const progressBlockRef = useRef(null);
  const progressRef = useRef(null);
  const digitRef = useRef(null);
  const btnRef = useRef(null);
  const mobileImgWrapperRef = useRef(null);
  const animatedContentListRef = useRef(null);

  const { ONE_YEAR_TRIAL_PLAN } = useStore();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: progressBlockRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    tl1
      .to(progressBlockRef.current, {
        opacity: 1,
        duration: 1,
      })
      .to(digitRef.current, {
        duration: 0.5,
        text: "25%",
        onUpdate: () => {
          if (digitRef.current) {
            digitRef.current.innerHTML = "25%";
          }
        },
      })
      .to(
        ":root",
        {
          duration: 0.5,
          "--progress": 25,
          onUpdate: () => {
            if (progressRef.current) {
              progressRef.current.style.setProperty("--progress", 25);
            }
          },
        },
        "<"
      )
      .to(digitRef.current, {
        duration: 0.5,
        text: "50%",
        onUpdate: () => {
          if (digitRef.current) {
            digitRef.current.innerHTML = "50%";
          }
        },
      })
      .to(
        ":root",
        {
          duration: 0.5,
          "--progress": 50,
          onUpdate: () => {
            if (progressRef.current) {
              progressRef.current.style.setProperty("--progress", 50);
            }
          },
        },
        "<"
      )
      .to(digitRef.current, {
        duration: 0.5,
        text: "75%",
        onUpdate: () => {
          if (digitRef.current) {
            digitRef.current.innerHTML = "75%";
          }
        },
      })
      .to(
        ":root",
        {
          duration: 0.5,
          "--progress": 75,
          onUpdate: () => {
            if (progressRef.current) {
              progressRef.current.style.setProperty("--progress", 75);
            }
          },
        },
        "<"
      )
      .to(digitRef.current, {
        duration: 0.5,
        text: "99%",
        onUpdate: () => {
          if (digitRef.current) {
            digitRef.current.innerHTML = "99%";
          }
        },
      })
      .to(
        ":root",
        {
          duration: 0.5,
          "--progress": 96,
          onUpdate: () => {
            if (progressRef.current) {
              progressRef.current.style.setProperty("--progress", 96);
            }
          },
        },
        "<"
      )
      .to(btnRef.current, {
        opacity: 1,
        duration: 0.5,
      })
      .to(mobileImgWrapperRef.current, {
        opacity: 1,
        duration: 0.5,
      })
      .to(animatedContentListRef.current, {
        opacity: 1,
        duration: 0.5,
        height: isDesktop ? getRem(260) : getRem(220),
        width: isDesktop ? getRem(600) : getRem(360),
      });
    // eslint-disable-next-line
  }, []);

  return (
    <section className="weOffer_section">
      <div className="weOffer_card">
        <div className="weOffer_textContent">
          <div>
            <h3 className="weOffer_title">What we offer</h3>
            <p className="weOffer_subtitle">
              Your shortest way
              <br /> to a safe Internet
            </p>
          </div>
          <div className="weOffer_progressBlock" ref={progressBlockRef}>
            <svg
              viewBox={isDesktop ? "0 0 160 160" : "0 0 100 100"}
              className="circular-progress"
              ref={progressRef}
            >
              <circle className="fg"></circle>
            </svg>
            <div>
              <p className="digit" ref={digitRef}>
                1%
              </p>
              <p className="desc">Digital security and freedom</p>
            </div>
          </div>
          <button
            className="secondary-button weOffer_trialBtn"
            ref={btnRef}
            onClick={() => handleSelectPlan(ONE_YEAR_TRIAL_PLAN, "freeTrial")}
          >
            Get free trial
          </button>
        </div>
        <div className="weOffer_imageWrapper" ref={mobileImgWrapperRef}>
          <ul className="animatedContent_list" ref={animatedContentListRef}>
            <li className="animatedContent_listItem">
              {animationTextContent[0]}
            </li>
            <li className="animatedContent_listItem">
              {animationTextContent[1]}
            </li>
            <li className="animatedContent_listItem">
              {animationTextContent[2]}
            </li>
            <li className="animatedContent_listItem">
              {animationTextContent[3]}
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default WeOfferSection;
