import { useState } from "react";

import { useStore } from "../store";

import { openInNewTab, requiredAuth } from "../utils";

import { subscribePaypal, subscribeStripe, subscribeStripeNA } from "../api";

const useSelectPlan = ({ authorizeRequest, email } = {}) => {
  const [paymentMethodPopup, setPaymentMethodPopup] = useState({
    show: false,
    payViaStripe: () => null,
    payViaPaypal: () => null,
    freeTrial: false,
    currPrice: 0,
  });

  const { getProductsLoading } = useStore();

  const handleSelectPlan = (selectedPlan, isFreeTrial) => {
    if (getProductsLoading) {
      return;
    }

    if (!email) {
      // FOR HOME & PROFILE PAGES
      const payViaStripe = () => {
        subscribeStripe({
          plan: selectedPlan.stripe.id,
          isFreeTrial: !!isFreeTrial,
        }).then((response) => {
          const checkoutUrl = response.data.checkout_url;
          openInNewTab(checkoutUrl);
        });
      };

      const payViaPaypal = () => {
        subscribePaypal({
          plan: selectedPlan.paypal.id,
          isFreeTrial: !!isFreeTrial,
        }).then((response) => {
          const checkoutUrl = response.data.redirect_url;
          openInNewTab(checkoutUrl);
        });
      };

      if (isFreeTrial) {
        setPaymentMethodPopup({
          show: true,
          payViaStripe: requiredAuth(payViaStripe, authorizeRequest),
          payViaPaypal: requiredAuth(payViaPaypal, authorizeRequest),
          freeTrial: true,
          currPrice: selectedPlan.stripe.unit_amount,
        });
      } else {
        setPaymentMethodPopup({
          show: true,
          payViaStripe: requiredAuth(payViaStripe, authorizeRequest),
          payViaPaypal: requiredAuth(payViaPaypal, authorizeRequest),
        });
      }
    } else {
      // FOR PAYWALL PAGE
      const payViaStripe = () => {
        subscribeStripeNA({
          plan: selectedPlan.stripe.id,
          email,
          isFreeTrial: !!isFreeTrial,
        }).then((response) => {
          const checkoutUrl = response.data.checkout_url;
          openInNewTab(checkoutUrl);
        });
      };

      if (isFreeTrial) {
        setPaymentMethodPopup({
          show: true,
          payViaStripe,
          freeTrial: true,
          currPrice: selectedPlan.stripe.unit_amount,
        });
      } else {
        setPaymentMethodPopup({ show: true, payViaStripe });
      }
    }
  };

  return { handleSelectPlan, paymentMethodPopup, setPaymentMethodPopup };
};

export { useSelectPlan };
